import { gql } from "@apollo/client";

export const ALL_QUESTIONS = gql`
  query questions {
    questions(tags: ["publish"]) {
      _id
      question
      tags
      answers {
        answer
        points
      }
    }
  }
`;
