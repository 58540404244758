const buzzer = `${process.env.PUBLIC_URL}/mp3/buzzer.mp3`; //when a player buzzes in in head 2 head
const wrong = `${process.env.PUBLIC_URL}/mp3/wrong.mp3`;
const correct = `${process.env.PUBLIC_URL}/mp3/correct.mp3`;
const reveal = `${process.env.PUBLIC_URL}/mp3/reveal.mp3`; //regular round reveal
const fmReveal = `${process.env.PUBLIC_URL}/mp3/fm-reveal.mp3`; //fast money reveal
const fmRevealWrong = `${process.env.PUBLIC_URL}/mp3/reveal-wrong.mp3`;
const fmRevealRight = `${process.env.PUBLIC_URL}/mp3/reveal-right.mp3`;
const fmBG = `${process.env.PUBLIC_URL}/mp3/rr-bg.mp3`; //background music for fast money guessing
const theme = `${process.env.PUBLIC_URL}/mp3/theme.mp3`;


const soundManager = {
    buzzer,
    wrong,
    correct,
    reveal,
    theme,
    'fm-reveal' : fmReveal,
    'fm-bg' : fmBG,
    'fm-reveal-wrong' : fmRevealWrong,
    'fm-reveal-right' : fmRevealRight
};

export default soundManager;