import React, { useEffect, useRef, useContext } from "react";
import { GameContext } from "../contexts/gameContext";
import MuteButton from "./MuteButton";

const SoundPlayer = ({ sound }) => {
  console.log(sound);

  const player = useRef(null);

  const { gameState, clearCurrentSound, muted, resetSoundCanPlay } = useContext(
    GameContext
  );

  //console.log(currentSound);

  const handlePlayerLoad = (e) => {
    console.log("play");
    e.target.play();
  };

  const handlePlayerComplete = (e) => {
    console.log("play complete");
    clearCurrentSound();
  };

  useEffect(() => {
    if (!gameState.soundCanPlay) {
      console.log("pausing");
      //player.current.currentTime = 0;
      player.current.pause();
      resetSoundCanPlay();
    }
  }, [gameState.soundCanPlay]);

  useEffect(() => {
    if (player != null && player.current !== null) {
      player.current.addEventListener("canplay", handlePlayerLoad);
      player.current.addEventListener("ended", handlePlayerComplete);

      console.log("sound player first render");
    }

    return () => {
      if (player != null && player.current !== null) {
        player.current.addEventListener("canplay", handlePlayerLoad);
        player.current.addEventListener("ended", handlePlayerComplete);
      }
    };
  }, []);

  return (
    <>
      <audio
        className="SoundPlayer"
        src={sound !== undefined ? sound : null}
        ref={player}
        muted={muted}
      ></audio>
      <MuteButton muted={muted} />
    </>
  );
};

export default SoundPlayer;
