import React, { useContext } from "react";
import { getUniqueTags, sortAndFilterTags } from "../helpers";

const TagSelect = ({
  //array of questions from which to draw tag information
  questions,
  //the min number of questions a tag must appear on
  minQuestions = 8, 
  //the max number of questions a tag must appear on to appear in the select
  maxQuestions,
  //callback to call when the select changes
  onChange = (tag)=>{
      throw new Error("No onChange handler defined for this TagSelect");
  }
}) => {
  const allTags = getUniqueTags(questions);
  const tagsToShow = sortAndFilterTags(allTags,minQuestions,maxQuestions);

  return <select className="TagSelect" onChange={e=> onChange(e.currentTarget.value,e)}>
      <option value="null">All Tags</option>
      {tagsToShow.map((tag,i)=>{

          return <option value={tag.tag} key={`${tag}-${i}`}>{tag.tag} ({tag.count})</option>
      })}
  </select>;
};

export default TagSelect;
